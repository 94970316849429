<template>
  <div class="about">
    <div class="hero is-info">
      <div class="container">
        <h1 class="is-size-3">{{ $t("snippet.title") }}</h1>
      </div>
    </div>
    <div class="columns is-multiline">
      <div class="column is-one-quarter">
        <div class="box">
          <h2 class="subtitle">{{ $t("snippet.list")}}</h2>
          <ul v-for="journal in history">
            <li><a @click="showSnippet(journal)">{{ journal.title}}</a></li>
          </ul>
        </div>
      </div>
      <div class="column">
        <div class="box">
          <h2 class="subtitle">{{ current.title }}</h2>
          <textarea class="textarea is-info" v-bind:placeholder="$t('snippet.placeholder')" rows="10" v-model="current.content"></textarea>
          <hr>
          <button class="button is-info" type="button" @click="saveSnippet" v-tooltip="$t('snippet.save')">
            <font-awesome-icon icon="save" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {UserClient} from "@/pb/user_service_grpc_web_pb";
import {SnippetServiceClient} from "@/pb/snippet_service_grpc_web_pb";
import {GetUserRequest} from "@/pb/user_service_pb";
import {GetSnippetsRequest, SaveSnippetRequest} from "@/pb/snippet_service_pb";
import {Snippet} from "@/pb/model_pb";
import common from "@/utility/common";

export default {
  name: "Snippet",
  data() {
    return {
      current: {},
      history: [],
      currentTitle: "",
      today: {},
      isAdd: true
    }
  },
  methods: {
    showSnippet: function(journal) {
      this.current = journal
    },
    saveSnippet: function() {
      let token = this.$cookies.get("$t")
      let ts = new proto.google.protobuf.Timestamp()
      ts.setSeconds(Math.floor(this.today.getTime() / 1000))
      ts.setNanos((this.today.getTime() % 1000) * 1e6)
      let snippet = new Snippet()
      snippet.setContent(this.current.content)
      snippet.setUserEmail(this.current.userEmail)
      snippet.setTitle(this.current.title)
      snippet.setCreatedAt(ts)
      snippet.setUpdatedAt(ts)
      let metadata = {'authorization': token}
      this.client = new SnippetServiceClient(common.SECURE_HOST, null, {
        "grpc.max_receive_message_length": common.MESSAGE_LENGTH,
        "grpc.max_send_message_length": common.MESSAGE_LENGTH
      })
      let saveSnippetRequest = new SaveSnippetRequest()
      saveSnippetRequest.setSnippet(snippet)
      if (this.isAdd) {
        saveSnippetRequest.setOperation("add")
      } else {
        saveSnippetRequest.setOperation("update")
      }
      this.client.saveSnippet(saveSnippetRequest, metadata, (err, response)=>{
        if (err != null) {
          alert("failed to save journal: " + err)
          return
        }
      })
    },
    getSnippets: function(email, token) {
      let metadata = {'authorization': token}
      this.client = new SnippetServiceClient(common.SECURE_HOST, null, {
        "grpc.max_receive_message_length": common.MESSAGE_LENGTH,
        "grpc.max_send_message_length": common.MESSAGE_LENGTH
      })
      let getSnippetsRequest = new GetSnippetsRequest()
      getSnippetsRequest.setUserEmail(email)
      this.client.getSnippets(getSnippetsRequest, metadata, (err, response)=>{
        if (err != null) {
          alert("failed to get journals: " + err)
        }
        // building the snippet list on left side
        this.history = response.toObject().snippetsList
        if (this.history.length == 0 || this.history == [] || this.history[0].title != this.currentTitle) {
          let newSnippet = {}
          newSnippet.title = this.currentTitle
          newSnippet.userEmail = email
          this.history.unshift(newSnippet)
        } else {
          this.isAdd = false
        }
        this.current = this.history[0]
      })
    }
  },
  mounted() {
    let uid = this.$cookies.get("$uid")
    let token = this.$cookies.get("$t")
    this.today = new Date()
    this.currentTitle = this.today.getFullYear() + "-" + (this.today.getMonth() + 1) + "-" + this.today.getDate()
    if (this.$store.getters.isLogIn) {
      this.user = this.$store.getters.getUser
      if (this.user.email == "" || this.user.email == undefined || this.user.email == null) {
        let userEmail = this.CryptoJS.AES.decrypt(
            uid, "jumbosecret").toString(this.CryptoJS.enc.Utf8);

        let metadata = {'authorization': token}
        this.client = new UserClient(common.SECURE_HOST, null, {
          "grpc.max_receive_message_length": common.MESSAGE_LENGTH,
          "grpc.max_send_message_length": common.MESSAGE_LENGTH
        })
        let getUserRequest = new GetUserRequest()
        getUserRequest.setEmail(userEmail)
        this.client.getUser(getUserRequest, metadata, (err, response)=>{
          if (err != null) {
            alert("Failed to get user: " + err)
            return
          }
          if (response != null) {
            this.user = response.toObject().usersList[0]
            this.$store.commit('setLoggedInUser', this.user)
            this.$root.$emit('updateAuth', true)
            this.getSnippets(this.user.email, token)
          }
        })
      } else {
        this.getSnippets(this.user.email, token)
      }
    }
  }
}
</script>
